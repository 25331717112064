import { mixins } from "@/plugins/mixins";
import rideRecordDetail from '@/views/business/flow/detail';
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "oweFeeRecord",
  mixins: [mixins],
  components: {
    rideRecordDetail
  },
  data() {
    return {
      showAllFee: false,
      showTotalFee: false,
      showImpDialog: false,
      detailDrawer: false,
      inputFile: '',
      disable: true,
      currentRow: {},
      allFee: '',
      phone: '',
      plateNo: '',
      total: '',
      columns: [{
        label: '所属机构',
        prop: 'orgName'
      }, {
        label: '用户名称',
        prop: 'customerName'
      }, {
        label: '电话',
        prop: 'phone'
      }, {
        label: '骑行记录时间',
        prop: 'rideRecordTime'
      }, {
        label: '骑行距离',
        prop: 'durations'
      }, {
        label: '欠费金额',
        prop: 'oweFee'
      }, {
        label: '站外停放费用',
        prop: 'nonParkFee',
        customRender: props => {
          return props.row.nonParkFee ? props.row.nonParkFee : 0;
        }
      }, {
        label: '超运营停放费用',
        prop: 'outFenceFee',
        customRender: props => {
          return props.row.outFenceFee ? props.row.outFenceFee : 0;
        }
      }, {
        label: '创建时间',
        prop: 'createTime'
      }],
      topButtons: [{
        name: '导出',
        code: 'oweFee_export',
        type: 'primary',
        disabled: true,
        click: this.inputInfo,
        permissions: ['oweFee_export']
      }],
      linkButtons: [{
        name: '查看骑行记录',
        code: 'oweFeeRecord',
        click: this.parkRecordDetail,
        permissions: ['oweFeeRecord']
      }],
      searchItems: [{
        prop: 'phone',
        label: '手机号码'
      }, {
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }]
    };
  },
  mounted() {},
  methods: {
    parkRecordDetail(row) {
      this.currentRow = row;
      this.detailDrawer = true;
    },
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.oweFeeRecord.exportInfo(search).then(res => {
        this.blobFile(res, '欠费记录信息.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    import() {
      this.showImpDialog = true;
    },
    closeImport() {
      this.showImpDialog = false;
      this.$refs.tablePage.refresh();
    },
    impDialogClosed() {
      this.inputFile = '';
    },
    doImp() {
      if (!this.inputFile) {
        ElMessage.error('请选择文件');
        return;
      }
      this.showImpDialog = false;
      // 构造一个 FormData，把后台需要发送的参数添加到FormData里面，这样就可以直接把formData作为参数传递了
      let formData = new FormData();
      formData.append('file', this.inputFile); //接口需要传递的参数
      this.$api.business.oweFeeRecord.importInfo(formData).then(res => {
        this.blobFile(res, '欠费记录信息.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {});
    },
    fileChange(ev) {
      let selectFile = ev.target.files[0];
      if (selectFile) {
        let name = selectFile.name;
        let type = name.substring(name.lastIndexOf('.') + 1);
        if (type !== 'xls' && type !== 'xlsx') {
          this.$message.error('只能上传excel文件');
          this.inputFile = '';
        } else {
          this.inputFile = selectFile;
        }
      }
    }
  }
};